import errors from 'assets/errors.json';

/**
 * An error that is thrown when a request to the backend fails. It
 * contains both an error message and a status code, and inherits from
 * the Error class.
 */
export default class ResponseError {
  public readonly message: keyof typeof errors;

  public readonly status: number;

  constructor(message: keyof typeof errors, status = 500) {
    this.message = message;
    this.status = status;
  }
}
