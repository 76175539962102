import { Autocomplete } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import mapboxgl, { LngLatBoundsLike, Popup } from 'mapbox-gl';
import { toast } from 'react-toastify';

const SearchByCountry = ({ map }: {map: mapboxgl.Map | null }) => {
  const [currentPopup, setCurrentPopup] = useState<Popup>();

  const handleChange = (event: any, newValue: any) => {
    setSearchQuery(newValue); // Update searchQuery state with the selected option
    if (!newValue) return;

    if (currentPopup) {
      currentPopup.remove();
    }
    if (!map) return;

    // add a popup to the marker
    const newPopup = new mapboxgl.Popup({ closeButton: false })
      .setLngLat([newValue.lon, newValue.lat])
      .setHTML(`<h3>${newValue.display_name}</h3>`)
      .addTo(map);

    setCurrentPopup(newPopup);

    const boundingBox = [
      newValue.boundingbox[2], // minLng
      newValue.boundingbox[0], // minLat
      newValue.boundingbox[3], // maxLng
      newValue.boundingbox[1], // maxLat
    ];

    map.fitBounds(boundingBox as unknown as LngLatBoundsLike, {
      padding: 100,

    });
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    const url = new URL('https://nominatim.openstreetmap.org/search');
    const params: { [key: string]: string } = {
      q: searchQuery,
      format: 'json',
      limit: '10',
    };

    // Append query parameters to the URL
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const fetchOptions = async () => {
      try {
        if (searchQuery.trim() === '') {
          setOptions([]);
          return;
        }

        const response = await fetch(url, { method: 'GET' });
        setOptions(await response.json());
      } catch (error) {
        toast.error('Error fetching data');
      }
    };

    fetchOptions();
  }, [searchQuery]); // Fetch options whenever searchQuery changes

  return (
    <Autocomplete
      sx={{
        width: '100%',
      }}
      placeholder="Search"
      onChange={handleChange}
      getOptionLabel={(option) => option.display_name}
      onInputChange={(event, newInputValue) => {
        setSearchQuery(newInputValue);
      }}
      id="controllable-states-demo"
      options={options}
    />
  );
};

export default SearchByCountry;
