// errorHandler.ts for Groundtruth plot and collection operations
import ResponseError from 'api/remotefs/ResponseError';
import errors from 'assets/errors.json';
import { toast } from 'react-toastify';

export default function handleError(error: { message?: string; data?: { detail: string } }): void {
  if (error instanceof ResponseError) {
    const errorMessage = errors[error.message]?.dialog;
    toast.error(errorMessage);
  } else if (error && error.data) {
    toast.error(error.data.detail);
  } else {
    toast.error('An unexpected error occurred.');
  }
}
