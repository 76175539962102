import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography, Stack,
  useColorScheme,
} from '@mui/joy';
import { useParams } from 'react-router-dom';
import {
  PlotGroundTruth, PlotProperties, PlotsResponse, LandCoverClassesResponse,
} from 'api/openapi/generated/RawApi';
import KanopApi from 'api/openapi/KanopApi';
import { toast } from 'react-toastify';
import handleError from 'utils/ErrorHandler';
import LandCoverSelector from '../Inputs/LandCoverSelector';
import TextInput from '../Inputs/TextInput';

interface PopupFormProps {
  setOpen: (open: boolean) => void;
  map: mapboxgl.Map | null,
  draw: MapboxDraw | null;
  currentFeature: PlotGroundTruth[] | null;
  properties: PlotProperties | undefined;
  setFeature: (feature: PlotGroundTruth | undefined) => void;
  setPlotData: (plotData: PlotsResponse) => void;
  collectionLocked?: boolean;
  removeLayerAndSource: (map: mapboxgl.Map) => void;
  refetchLandCover: () => void;
  setproperties: (properties: PlotProperties) => void;
  landCover: LandCoverClassesResponse | undefined,
}
const PopupForm = ({
  setOpen,
  currentFeature,
  removeLayerAndSource,
  setFeature,
  map,
  refetchLandCover,
  properties,
  setproperties,
  draw,
  setPlotData,
  landCover,
  collectionLocked,
}: PopupFormProps) => {
  const { collectionId } = useParams<{ collectionId: string | undefined }>();
  const [formState, setFormState] = useState<PlotProperties>({
    plotReference: properties?.plotReference || '',
    observationDate: properties?.observationDate || '',
    treeHeightMean: properties?.treeHeightMean,
    canopyHeightMean: properties?.canopyHeightMean,
    canopyCover: properties?.canopyCover,
    agbValue: properties?.agbValue || null,
    landCoverClassId: properties?.landCoverClassId,
    soilOrganicCarbon: properties?.soilOrganicCarbon,
    comments: properties?.comments,
    collectionId,
  });

  const { mode, systemMode } = useColorScheme();
  const darkMode = mode === 'dark' || systemMode === 'dark';

  const [isError, setIsError] = useState(false);

  const handleChange = (e: { target: { name: string; value: string; }; }) => {
    const { name, value } = e.target;
    if (value === '') {
      setFormState((prev) => ({ ...prev, [name]: null }));
      return;
    }
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  // Fetch plots data from the server
  const {
    data: plot,
    refetch,
  } = KanopApi.useGetPlotsForCollectionsCollectionsCollectionIdPlotsGetQuery({
    collectionId: collectionId as string,
  });

  const [uploadPlot,
    {
      isLoading: isUploading,
    },
  ] = KanopApi.useUploadGtForPlotsCollectionsCollectionIdPlotsPostMutation();
  const [upDatePlot] = KanopApi.useUpdateGtForPlotsCollectionsCollectionIdPlotsPatchMutation();

  const handleFeatureSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!currentFeature) {
      setOpen(false);
      return;
    }

    if (currentFeature.length < 1) return;

    // Check if formState has at least one indicator filled
    if (formState?.canopyCover
       || formState?.canopyHeightMean
       || formState?.treeHeightMean
       || formState?.soilOrganicCarbon
       || formState?.agbValue
       || formState?.landCoverClassId) {
      const newFeature = {
        ...currentFeature[0],
        properties: {
          ...currentFeature?.[0].properties,
          ...formState, // Overwrite with new properties from formState
        },
      };

      if (newFeature.properties.plotId) {
        // plot already exists, update plot
        upDatePlot({
          collectionId: collectionId as string,
          featureCollectionPlotGroundTruthInput: {
            type: 'FeatureCollection',
            features: [newFeature] as unknown as PlotGroundTruth[],
          },
        }).unwrap().then(() => {
          // Clear all existing sources and layers from the map to make room for incoming ones

          removeLayerAndSource(map as mapboxgl.Map);
          refetch();
          setOpen(false);
          setIsError(false);
          setFeature(newFeature);
          setproperties(newFeature.properties);
          setPlotData(plot as PlotsResponse);
          toast.success('Plot data successfully updated');
        }).catch((err) => {
          handleError(err);
        });
      } else {
        // plot does not exist, create new plot
        uploadPlot({
          collectionId: collectionId as string,
          featureCollectionPlotGroundTruthInput: {
            type: 'FeatureCollection',
            features: [newFeature] as unknown as PlotGroundTruth[],
          },
        }).unwrap().then(() => {
          setFeature(newFeature);
          setOpen(false);
          setIsError(false);
          setproperties(newFeature.properties);
          toast.success('Plot data successfully added');
          draw?.deleteAll();
          // Clear all existing sources and layers from the map to make room for incoming ones
          removeLayerAndSource(map as mapboxgl.Map);
          refetch();
          setPlotData(plot as PlotsResponse);
        }).catch((error) => {
          handleError(error);
        });
      }
    } else {
      setIsError(true); // alert user to add at least one undicator paremeter
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // Set the form state to the properties of the current feature
  useEffect(() => {
    setFormState({
      plotReference: properties?.plotReference || '',
      observationDate: properties?.observationDate || '',
      treeHeightMean: properties?.treeHeightMean,
      canopyHeightMean: properties?.canopyHeightMean,
      canopyCover: properties?.canopyCover,
      agbValue: properties?.agbValue || null,
      landCoverClassId: properties?.landCoverClassId,
      soilOrganicCarbon: properties?.soilOrganicCarbon,
      comments: properties?.comments,
      collectionId,
    });
  }, [properties, collectionId]);

  // Create an array of form fields based on the formState object
  const formFields = Object.entries(formState).map(([key, value]) => {
    let placeholder = '';
    let type = '';
    switch (key) {
      case 'plotReference':
        placeholder = 'Plot reference *';
        type = 'text';
        break;
      case 'observationDate':
        placeholder = 'Observation date *';
        type = 'date';
        break;
      case 'treeHeightMean':
        placeholder = 'Tree height mean (m)';
        type = 'number';
        break;
      case 'canopyHeightMean':
        placeholder = 'Canopy height mean (m)';
        type = 'number';
        break;
      case 'canopyCover':
        placeholder = 'Canopy cover (%)';
        type = 'number';
        break;
      case 'agbValue':
        placeholder = 'Aboveground biomass (t / ha)';
        type = 'number';
        break;
      case 'landCoverClassId':
        placeholder = 'Land cover';
        // No input rendered for 'landCoverClassId'
        return null;
      case 'soilOrganicCarbon':
        placeholder = 'Soil organic carbon stock (tc / ha)';
        type = 'number';
        break;
      case 'comments':
        placeholder = 'Comment';
        type = 'text';
        break;
      default:
        placeholder = '';
    }
    return key !== 'landCoverClassId' && key !== 'color' && key !== 'collectionId' ? (
      <TextInput
        key={key}
        disabled={collectionLocked}
        name={key}
        value={value?.toString() as string}
        onChange={handleChange}
        placeholder={placeholder}
        sx={{ fontSize: '14px' }}
        type={type}
      />
    ) : null;
  });

  // Find the index where 'observationDate' is located
  const observationDateIndex = formFields.findIndex((field) => field?.props.name === 'observationDate');

  // Insert the LandCoverSelector component right after 'observationDate'
  if (observationDateIndex !== -1) {
    formFields.splice(
      observationDateIndex + 1,
      0, (
        <LandCoverSelector
          key="landCoverSelector"
          formState={formState}
          disabled={collectionLocked}
          setFormState={setFormState}
          landCover={landCover}
          refetch={refetchLandCover}
        />
      ),
    );
  }

  return (
    <Stack
      sx={{
        borderRadius: 'md',
        backgroundColor: darkMode ? '#021010' : '#FFFFFF',

      }}
      className="form-container"
    >
      <form onSubmit={handleFeatureSubmit}>
        <Typography level="body-sm" marginBottom={1} textAlign="center">Plot data</Typography>
        {formFields}
        <div className="btn-group">
          <div className="form-group">
            <Button
              type="button"
              color="neutral"
              className="cancel-btn"
              onClick={handleCancel}
              sx={{
                display: collectionLocked ? 'none' : 'inherit',
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="form-group">
            <Button
              type="submit"
              color="primary"
              className="submit-btn"
              loading={isUploading}
              sx={{
                display: collectionLocked ? 'none' : 'inherit',
              }}
            >
              Save
            </Button>
          </div>
        </div>
        {collectionLocked && (
        <Typography level="body-xs" textAlign="center">
          These fields are locked due to the collection status and cannot be edited.
        </Typography>
        )}
      </form>
      {isError && (
      <Typography level="body-sm" color="danger" textAlign="center">
        To create this plot, please ensure you have added at least one data point.
      </Typography>
      )}
    </Stack>
  );
};

export default PopupForm;
